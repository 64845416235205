import React from 'react'

const Maintenance = () => (
  <div>
    <h1>
      System Maintenance
    </h1>
    <p>
      We're updating the system to serve you better. Please check back soon.
    </p>
  </div>
)

export default Maintenance;
